'use client';

import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

import { env } from '~/env';

if (typeof window !== 'undefined') {
  posthog.init(env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: env.NEXT_PUBLIC_POSTHOG_HOST,
    person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
  });
}
export function CSPostHogProvider({ children }: { children: React.ReactNode }) {
  return env.NODE_ENV === 'production' ? <PostHogProvider client={posthog}>{children}</PostHogProvider> : <>{children}</>;
}
